<template>
  <div
    class="p-1 h-[50px] flex justify-center text-xs text-gray-500 border-t-2"
    :class="props?.css_classes"
  >
    <div class="flex items-center justify-between w-full">
      <div class="flex w-fit h-fit items-center text-gray-500">
        <SelectInput
          v-model="current_limit"
          :required="true"
          :label="width > 675 ? 'Items per Page' : null"
          class="flex flex-row"
          container-class="flex flex-row whitespace-nowrap items-center"
          label-class="font-normal mr-1"
          :options="
            options.map((e, i) => ({
              key: e,
              value: e,
            }))
          "
        />
      </div>

      <div
        v-if="Math.ceil(records / current_limit) > 1"
        v-show="props.show_page_buttons"
        class="flex w-fit h-fit items-center text-gray-500"
      >
        <SolidButton
          class="font-bold w-12 mx-1"
          :class="{
            'bg-gray-400': props.page - 1 == 0,
          }"
          :disabled="props.page - 1 == 0"
          @click="updatePage(getFirst())"
        >
          First
        </SolidButton>
        <SolidButton
          class="font-bold w-12 mx-1"
          :class="{
            'bg-gray-400': props.page - 1 == 0,
          }"
          :disabled="props.page - 1 == 0"
          @click="updatePage(props.page - 1)"
        >
          &lt;
        </SolidButton>

        <div
          v-if="width > 835"
          class="flex w-fit h-fit items-center text-gray-500"
        >
          <div
            v-for="(item, index) in Math.ceil(records / current_limit)"
            :key="index"
          >
            <SolidButton
              v-show="!showPaginationSolidButton(index + 1)"
              class="font-bold w-12 mx-1"
              :class="index + 1 === props.page ? 'bg-primary-hover' : ''"
              :disabled="index + 1 === props.page"
              @click="updatePage(index + 1)"
            >
              {{ index + 1 }}
            </SolidButton>
          </div>
        </div>

        <SolidButton
          class="font-bold w-12 mx-1"
          :disabled="current_end_range === records"
          :class="{
            'bg-gray-400': current_end_range === records,
          }"
          @click="updatePage(props.page + 1)"
        >
          >
        </SolidButton>
        <SolidButton
          class="font-bold w-12 mx-1"
          :disabled="current_end_range === records"
          :class="{
            'bg-gray-400': current_end_range === records,
          }"
          @click="updatePage(getLast())"
        >
          Last
        </SolidButton>
      </div>

      <div class="flex w-fit h-fit items-center text-gray-500">
        <div v-if="width > 975">
          <span v-if="records">
            {{ (page - 1) * current_limit + 1 }} - {{ current_end_range }}
            {{ type }} of
          </span>
          {{ records }} Total Items
        </div>
        <div v-else>
          <span v-if="records">
            {{ (page - 1) * current_limit + 1 }} - {{ current_end_range }} of
          </span>
          {{ records }} Total
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SelectInput from '@/components/forms/SelectInput.vue';
import { watch } from 'vue';
import { useLocalStorage, useWindowSize } from '@vueuse/core';
import SolidButton from '@/components/buttons/SolidButton.vue';
const props = defineProps({
  type: {
    default: 'Records',
    type: String,
  },
  page: {
    default: 1,
    type: Number,
  },
  options: {
    // object/array defaults should be returned from a factory function
    default() {
      return [10, 25, 50];
    },
    type: Array,
  },
  limit: {
    default: 10,
    type: Number,
  },
  records: {
    default: 0,
    type: Number,
  },
  local_store: {
    default: '',
    type: String,
  },
  update_on: {
    default: 0,
    type: Number,
  },
  css_classes: {
    default: '',
    type: String,
  },
  scrollTarget: {
    default: '',
    type: String,
  },
  show_page_buttons: {
    default: true,
    type: Boolean,
  },
});
const { width } = useWindowSize();

const emit = defineEmits(['paginate', 'updateLimit']);

const current_limit = useLocalStorage(
  props.local_store,
  props.limit.toString()
);

let current_end_range =
  props.records < props.page * current_limit.value
    ? props.records
    : props.page * current_limit.value;

watch(props, () => {
  if (props.update_on) {
    current_end_range =
      props.records < props.update_on * current_limit.value
        ? props.records
        : props.update_on * current_limit.value;
  }
  localStorage.setItem(props.local_store, props.limit.toString());
  current_end_range =
    props.records < props.page * current_limit.value
      ? props.records
      : props.page * current_limit.value;
});

const scrollToTop = () => {
  if (!props.scrollTarget) {
    return;
  }
  const target = document.getElementById(props.scrollTarget);

  target.scrollIntoView({
    behavior: 'smooth',
    alignToTop: true,
  });
};

watch(current_limit, () => {
  emit('updateLimit', current_limit);
});

function showPaginationSolidButton(index) {
  return Math.abs(index - props.page) > 2;
}

function updatePage(page) {
  scrollToTop();
  emit('paginate', page);
}

function getLast() {
  return Math.ceil(props.records / current_limit.value);
}

function getFirst() {
  return 1;
}
</script>
