import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import { useUsersStore } from '@/stores/useUsers';
import { getDatePart } from '@/utils/helpers';

import router from '@/router';
import { useSearchStore } from './useSearch';
import { DeletedActivityString } from '@/utils/constants';
import { apiGet, apiPost, apiPut } from '@/utils/api';
import getPath from '@/utils/getPath';

export const useWorkItemsStore = defineStore('workItems', {
  state: () => ({
    workItems: [],
    notFound: false,
    workItem: null,
    workItemChargesTotal: 0,
    workItemPaymentsTotal: 0,
    workItemAdjustmentsTotal: 0,
    workItemActivities: [],
    workItemCharges: [],
    workItemTransactions: [],
    isLoading: true,
    isClaimStatusLoading: false,
    activityLookup: [],
    queryResults: [],
    activityCursor: 0,
    activityLimit: 10,
    activityPage: 1,
    activityDirection: 'DESC',
    activityField: 'mod_timest',
    chargesCursor: 0,
    chargesLimit: 10,
    chargesPage: 1,
    chargesDirection: 'ASC',
    chargesField: 'mod_timest',
    transactionsCursor: 0,
    transactionsLimit: 10,
    transactionsPage: 1,
    transactionsDirection: 'ASC',
    transactionsField: 'mod_timest',
    activityToDelete: {},
    editActivityModeEnabled: false,
  }),
  getters: {
    getWorkItemValue: state => {
      return field => {
        return state.workItem?.[field];
      };
    },
    chargesRecords: state => {
      return state.workItemCharges.length;
    },
    chargesPaginated: state => {
      const from = Number(state.chargesLimit) * Number(state.chargesPage);
      const to = from - Number(state.chargesLimit);
      return state.workItemCharges.slice(to, from);
    },
    activityRecords: state => {
      return state.workItemActivities.length;
    },
    activityPaginated: state => {
      const from = Number(state.activityLimit) * Number(state.activityPage);
      const to = from - Number(state.activityLimit);
      return state.workItemActivities.slice(to, from);
    },
    transactionsRecords: state => {
      return state.workItemTransactions.length;
    },
    transactionsPaginated: state => {
      const from =
        Number(state.transactionsLimit) * Number(state.transactionsPage);
      const to = from - Number(state.transactionsLimit);
      return state.workItemTransactions.slice(to, from);
    },
  },
  actions: {
    async fetchWorkItem(id) {
      this.isLoading = true;
      try {
        this.notFound = false;

        const responseData = await apiGet(getPath(`workItems/${id}`));

        this.workItem = responseData?.data ?? {};
        this.workItemPaymentsTotal = responseData.data.payments_summary;
        this.workItemAdjustmentsTotal = responseData.data.adjustments_summary;
        this.workItemChargesTotal = responseData.data.charges_summary;
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? err);

        if (err.status == 404) {
          this.notFound = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async setWorkItemStatus(workItemId, status, fromSearch = false) {
      const payload = { fromSearch: fromSearch };
      try {
        const responseData = await apiPut(
          getPath(`assignments/${workItemId}?action=${status}`),
          payload
        );

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          return responseData;
        }
      } catch (err) {
        const errorBody = await err.response.json();
        toast.error(errorBody?.message ?? err);
        console.error(errorBody?.message);
        return errorBody;
      }
    },
    async fetchActivitiesLookup(id) {
      try {
        const response = await apiGet(getPath(`lookups/activities/${id}`));

        this.activityLookup =
          response?.data
            .filter(a => a.is_active) // Filter out where is_active is false
            .map(a => ({
              ...a,
              value: a.name,
              key: parseInt(a.activity_subtype_id),
            })) || [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? err);
      }
    },
    async fetchWorkItemActivities(
      id,
      page = 1,
      direction = 'desc',
      field = 'mod_timest',
      limit = 50
    ) {
      const users_store = useUsersStore();
      this.isLoading = true;
      this.activityPage = page;
      this.activityDirection = direction;
      this.activityField = field;

      try {
        const queryStringParameter = new URLSearchParams({
          cursor: page ? (page - 1) * limit : this.activityCursor,
          limit: limit,
          field: field,
          direction: direction,
          type: 'activities',
        });

        const response = await apiGet(
          getPath(`workItems/${id}/activities`),
          queryStringParameter
        );

        // determine if activity is deletable or editable by active user
        this.workItemActivities = [];
        for (const activity of response?.data ?? []) {
          let allowDelete = false;
          let allowEdit = false;
          // set up conditionals
          let userFromActivity = users_store.getUserByUsername(
            activity.username
          );
          // added for edgecase where getUserByUserName doesn't return a user
          if (!userFromActivity?.auxo_user_id) {
            await users_store.getAllUsers();
            userFromActivity = users_store.getUserByUsername(activity.username);
          }

          let activeUserIsAdmin = users_store.isActiveUserAdmin;

          let activeUserOwnsComment =
            userFromActivity.auxo_user_id ===
            users_store.activeUser.auxo_user_id;

          //wip,complete,note
          let isComment = [2, 3, 4].includes(activity.activity_type_id);
          let isFromToday =
            getDatePart(activity.mod_timest) ===
            getDatePart(new Date().toISOString());

          let hasBeenDeleted = activity.note === DeletedActivityString;

          if (
            isComment &&
            activeUserOwnsComment &&
            isFromToday &&
            !hasBeenDeleted
          ) {
            allowEdit = true;
          }

          if (isComment && activeUserIsAdmin && !hasBeenDeleted) {
            allowDelete = true;
          }
          this.workItemActivities.push({
            ...activity,
            showDeleteIcon: allowDelete,
            showEditIcon: allowEdit,
          });
        }
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWorkItemCharges(
      id,
      page = 1,
      direction = 'asc',
      field = 'mod_timest',
      limit = 50
    ) {
      this.isLoading = true;
      this.chargesPage = page;
      this.chargesDirection = direction;
      this.chargesField = field;

      try {
        const queryStringParameter = new URLSearchParams({
          cursor: page ? (page - 1) * limit : this.chargesCursor,
          limit: limit,
          field: field,
          direction: direction,
          type: 'charges',
        });

        const response = await apiGet(
          getPath(`workItems/${id}/activities`),
          queryStringParameter
        );
        this.workItemCharges = response?.data ?? [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWorkItemTransactions(
      id,
      page = 1,
      direction = 'asc',
      field = 'mod_timest',
      limit = 50
    ) {
      this.isLoading = true;
      this.transactionsPage = page;
      this.transactionDirection = direction;
      this.transactionField = field;

      try {
        const queryStringParameters = new URLSearchParams({
          cursor: page ? (page - 1) * limit : this.transactionsCursor,
          limit: limit,
          field: field,
          direction: direction,
          type: 'tx',
        });

        const response = await apiGet(
          getPath(`workItems/${id}/activities`),
          queryStringParameters
        );
        this.workItemTransactions = response?.data ?? [];
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
      } finally {
        this.isLoading = false;
      }
    },
    async getClaimStatus() {
      const users_store = useUsersStore();
      const auxo_user_id = users_store.activeUser?.auxo_user_id;
      try {
        const payload = {
          tin: this.workItem.tax_id,
          plan_id: this.workItem.current_plan_id,
          lookup_value: this.workItem.claim_number,
          reply_to: auxo_user_id,
        };

        const response = await apiPost(
          getPath(`workItems/${this.workItem.work_item_id}/claimStatus`),
          payload
        );

        return response;
      } catch (err) {
        const errorBody = await err.response.json();
        toast.error(
          errorBody?.message ??
            'Unable to retrieve claim status. Please check directly with the payer.'
        );
        console.error(errorBody?.message);
        return errorBody;
      }
    },
    async postActivity(
      workItemId,
      activityId,
      activityName,
      subactivityId,
      subActivityName,
      notes,
      ua_id,
      fromSearch
    ) {
      const users_store = useUsersStore();
      const auxo_user_id = users_store.activeUser?.auxo_user_id;

      const payload = {
        activity_type_id: activityId,
        activity_type: activityName,
        activity_subtype_id: parseInt(subactivityId, 10),
        activity_subtype: subActivityName,
        note: notes,
        ua_id,
        auxo_user_id,
        fromSearch,
      };

      try {
        const response = await apiPost(
          getPath(`workItems/${workItemId}/activities`),

          payload
        );
        return response;
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
        return null;
      }
    },
    async editWorkItemActivity(activityId, note, updateType) {
      const users_store = useUsersStore();
      const email = users_store.getActiveUserAttribute('email');
      const workItemId = this.workItem.revology_system_number;
      const payload = {
        actor_email: email,
        note: note,
        update_type: updateType,
      };

      try {
        const response = await apiPut(
          getPath(`workItems/${workItemId}/activities/${activityId}`),

          payload
        );
        return response;
      } catch (err) {
        const errorBody = await err.response.json();
        console.error(errorBody?.message ?? errorBody);
        return null;
      }
    },
    async navigateBack() {
      const search_store = useSearchStore();
      if (search_store.justSearched && search_store.input) {
        search_store.setJustSearched(false);
        search_store.returningFromPreviousSearch = true;
      }

      const nestedRoutes = ['/document'];
      if (window.history.state.back) {
        window.history.state.back.includes(nestedRoutes)
          ? router.push('/lead')
          : router.go(-1);
      } else {
        router.push('/lead');
      }
    },
    reset() {
      this.workItem = null;
    },
  },
});
