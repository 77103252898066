<template>
  <table
    v-if="workItem_store.workItemCharges.length > 0"
    class="table-auto w-full text-left border-0 drop-shadow-none"
  >
    <thead class="text-rev-sm sticky text-primary font-bold">
      <tr>
        <template v-for="(header, index) in CHARGES_HEADERS" :key="index">
          <TableHeader
            :id="header.key"
            :type="header.type"
            :show-sort="true"
            :title="header.value"
            :width="header.width"
            :offset="header.offset"
            title-styling="text-primary font-bold"
            @sort="sort"
          />
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-show="workItem_store.isLoading">
        <td colspan="8">
          <div class="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        </td>
      </tr>
    </tbody>

    <tbody v-show="!workItem_store.isLoading" class="text-rev-sm text-gray-700">
      <tr
        v-for="(item, itemIndex) in workItem_store.chargesPaginated"
        :key="itemIndex"
        :data-index="itemIndex"
        :class="itemIndex % 2 === 1 ? 'bg-gray-100' : 'bg-white'"
      >
        <td v-for="(header, headerIndex) in CHARGES_HEADERS" :key="headerIndex">
          <TableCellVue :item="item" :header="header" />
        </td>
      </tr>
    </tbody>
  </table>
  <div
    v-show="workItem_store.workItemCharges.length === 0"
    class="flex bg-white h-36 p-2 justify-center items-center text-primary text-rev-base"
  >
    No charges found
  </div>
  <VuePagination
    v-if="!workItem_store.isLoading"
    :page="workItem_store.chargesPage"
    local_store="charges_pagination"
    type="Recent Charges"
    :records="workItem_store.chargesRecords"
    :limit="Number(workItem_store.chargesLimit)"
    :show_page_buttons="false"
    @paginate="paginate"
    @updateLimit="updateLimit"
  />
</template>
<script setup>
import { onMounted } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import TableHeader from '@/components/table/TableHeader.vue';
import TableCellVue from '../../common/TableCellView.vue';
import VuePagination from '@/views/VuePagination.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useWorkItemsStore } from '@/stores/useWorkItems';

const workItem_store = useWorkItemsStore();

const CHARGES_HEADERS = [
  {
    value: 'Posting Date',
    key: 'mod_timest',
    type: 'date',
    width: '10%',
    sort: true,
  },
  { value: 'Code', key: 'activity_subtype', width: '20%', sort: true },
  { value: 'Description', key: 'note', width: '50%', sort: true },
  {
    value: 'Amount',
    key: 'amount',
    width: '10%',
    type: 'currency',
    sort: true,
  },
  {
    value: '',
    key: 'offset',
    width: '10%',
    offset: 'min-w-[65px]',
  },
];

async function paginate(page) {
  await workItem_store.fetchWorkItemCharges(
    props.workItemId,
    page,
    workItem_store.chargesDirection,
    workItem_store.chargesField
  );
}

async function updateLimit(limit) {
  workItem_store.chargesPage = 1;
  workItem_store.chargesLimit = Number(limit.value);
}

async function sort(direction, field) {
  await workItem_store.fetchWorkItemCharges(
    props.workItemId,
    1,
    direction,
    field
  );
  workItem_store.chargesDirection = direction;
  workItem_store.chargesField = field;
  workItem_store.chargesPage = 1;
}

onMounted(() => {
  const current_limit = useLocalStorage(
    'charges_pagination',
    Number(workItem_store.chargesLimit)
  );
  workItem_store.chargesLimit = current_limit;
});

const props = defineProps({
  workItemId: {
    type: String,
    default: '',
  },
});
</script>
<style scoped>
tr td {
  width: 25%;
}
</style>
